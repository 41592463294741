import React from "react"
import dayjs from "dayjs"
import { graphql } from "gatsby"

import {
  HeaderCentered,
  HeaderCenteredIntroText,
  Layout,
  RichText,
  Seo,
} from "../components/Shared"
import { GetANewBoiler } from "../components/Sections"
import { convertToDateWithSuffix } from "../utilities"

const TermsAndConditions = ({ data }) => {
  const terms = data.terms
  return (
    <Layout>
      <Seo
        url={data.site && data.site.siteMetadata.url + "/privacy-policy"}
        title={terms.seo && terms.seo.title}
        description={terms.seo.metaDescription.metaDescription}
        image={
          terms.seo &&
          terms.seo.image &&
          terms.seo.image.file &&
          terms.seo.image.file.url
        }
      />
      <HeaderCentered heading={terms.name} />
      <HeaderCenteredIntroText>
        <p>
          Last updated:{" "}
          {convertToDateWithSuffix(dayjs(terms.updatedAt).format("D")) + " "}
          {dayjs(terms.updatedAt).format("MMMM YYYY")}
        </p>
      </HeaderCenteredIntroText>
      <main className="wrapper-width wrapper-height">
        <RichText content={terms.pageContent} />
      </main>
      <GetANewBoiler backgroundColour="grey-lightest" />
    </Layout>
  )
}

export default TermsAndConditions

export const pageQuery = graphql`
  query getTermsAndConditions {
    site {
      siteMetadata {
        url
      }
    }
    terms: contentfulTextOnlyPages(
      contentful_id: { eq: "RCePWpfdyTZggUtPmG0mq" }
    ) {
      contentful_id
      name
      updatedAt
      pageContent {
        raw
      }
      seo {
        image {
          fluid {
            src
          }
          title
          description
        }
        title
        metaDescription {
          metaDescription
        }
      }
    }
  }
`
